import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";

export default () => {
  const [users, setusers] = useState([]);

    let axiosConfig = {
      withCredentials: true,
    };

      useEffect( () => {
          console.log("running ")
              try {
                  let res =  axios.get(
                      `https://boomtownblues.com/btbapi/users`,
                      axiosConfig
                  )
                  console.log("got users " + JSON.stringify(res.data))
                  setusers(res.data)
              } catch (error) {
                  console.log("ERROR" + error);
              }
                            try {
                  let cookie = axios.get(
                      `https://boomtownblues.com/btbapi/getcookie`,
                      axiosConfig
                  )
                  console.log("got cookie " + JSON.stringify(cookie.data))
              } catch (error) {
                  console.log("ERROR" + error);
              }



      },[]);

  // useEffect(() => {
  //   fetch('https://boomtownblues.com/api/users')
  //     .then((res) => res.json())
  //     .then((result) => {
  //
  //       setusers(result);
  //     });
  // }, []);

  return (
    <div className="container" style={{ marginTop: '50px' }}>
    <head>
        <title>My Serverless Application</title>
    </head>
    <h1>My Dashboard</h1>
    <p>Welcome to your Knowledge Center </p>
        <section class="resources">
            <h2>Resources</h2>
                Resources for your build
        </section>
        <section class="form">
            <h2>Awaiting Data</h2>
        </section>
    </div>
  );
};
