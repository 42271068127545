import {React} from 'react';

const Error = () => {

  return (
      <div>
  <head>
    <title>Error</title>
  </head>
  <body>
    <h2>Sadly, an Error occurred </h2>
  </body>
</div>
)}

export default Error
