import React from "react";
import { useNavigate } from 'react-router-dom';

export default () => {
    let navigate = useNavigate();
    const EndSession = async () => {
        await fetch(`/btbapi/endsession`);
        navigate('/logout');
  };

  return (
      <div className="nav-style">
    <nav>
      <div className="nav-wrapper">

          <a href="/" className="brand-logo" style={{ paddingLeft: "15px" }}>
            BoomTownBlues
          </a>

        <ul id="nav-mobile" className="right hide-on-med-and-down">
              <li>
                <a href="/dashboard">Dashboard</a>
              </li>
              <li>
                <a href="/login">Login</a>
              </li>
              <li>
                <a onClick={()=>EndSession}>Logout</a>
              </li>
        </ul>
      </div>
    </nav>
      </div>
  );
};
