import React, {Component, useState} from 'react';
import axios from "axios";


function SimpleForm() {
  const [albumName, setalbumName] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("form submitted" + e.target.name.value)
	  setalbumName(albumName => [...albumName, e.target.password.value])
	  setalbumName(albumName => [...albumName, e.target.name.value])
      let cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)btb-cookie\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    let axiosConfig = {
      withCredentials: true,
        crossdomain: true,
    };
              try {
                  let res = await axios.post(
                      `https://boomtownblues.com/btbapi/login`,
					  albumName
                  )
                  console.log("got users " + JSON.stringify(res.data))

              } catch (error) {
                  console.log("ERROR" + error);
              }

  }
  	  console.log("formdata " + JSON.stringify(albumName))
    return (
            <div className="container" style={{ marginTop: '50px' }}>
      <div >
          On your way to better bids
          <form action="/btbapi/login" method="post">
              Register
              <div>
                  <label htmlFor="username">Username</label>
                  <input id="username" name="username" type="text" autoComplete="username" required/>
              </div>
              <div>
                  <label htmlFor="current-password">Password</label>
                  <input id="current-password" name="password" type="password" autoComplete="current-password" required/>
              </div>
              <div>
                  <button type="submit">Sign in</button>
              </div>
          </form>
          <a href={'/register'}>Not Registered yet? </a>
      </div>
            </div>
    );
}

export default SimpleForm
