import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./dashboard.jsx";
import Register from './register'
import Login from './login'
import Error from './error'
import Parallax from './parallax'
import Nav from './nav'


const Router = (props) => {
  return (
    <BrowserRouter>
        <Nav/>
        <Routes>
          <Route path="/" element={<Parallax />} />
          <Route
            path="/dashboard"
            element={
                <Dashboard />
            }
          />
                      <Route
            path="/login"
            element={
                <Login />
            }
          />
          <Route
            exact
            path="/register"
            element={
                <Register />
            }
          />
          <Route
            path="/logout"
            element={
                <Dashboard />
            }
          />
            <Route path="*" element={<Error />} />
        </Routes>
    </BrowserRouter>
  );
};

export default Router;
