import {React} from 'react';
import Login from "./login";

const Register = () => {

  return (
	      <div className="container" style={{ marginTop: '50px' }}>
      <main>
		<form action="/btbapi/register" method="post">
			REGISTER
			<div>
				<label htmlFor="username">Username</label>
				<input id="username" name="username" type="text" autoComplete="username" required/>
			</div>
			<div>
				<label htmlFor="current-password">Password</label>
				<input id="current-password" name="password" type="password" autoComplete="current-password" required/>
			</div>
			<div>
				<button type="submit">Sign in</button>
			</div>
		</form>
	  </main>
		  </div>
  )}

export default Register;
