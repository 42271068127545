import React, { useState, useEffect } from "react";
import "./App.css";
import gsap from 'gsap'
import ScrollTrigger from "gsap/ScrollTrigger";
const axios = require("axios").default;


function App() {
  var shouldtaglinesberun = true;
  var counter = 0;

  gsap.registerPlugin(ScrollTrigger);

let getRatio = el => window.innerHeight / (window.innerHeight + el.offsetHeight);

console.log("counter " + counter)

  function getbackgroundimages () {
    gsap.utils.toArray("section").forEach((section, i) => {
      section.bg = section.querySelector(".bg");
      console.log("starting gsap utils to array")
      // Give the backgrounds some random images
      section.bg.style.backgroundImage = `url(https://picsum.photos/1600/800?random=${i})`;

      // the first image (i === 0) should be handled differently because it should start at the very top.
      // use function-based values in order to keep things responsive
      gsap.fromTo(section.bg, {
        backgroundPosition: () => i ? `50% ${-window.innerHeight * getRatio(section)}px` : "50% 0px"
      }, {
        backgroundPosition: () => `50% ${window.innerHeight * (1 - getRatio(section))}px`,
        ease: "none",
        scrollTrigger: {
          trigger: section,
          start: () => i ? "top bottom" : "top top",
          end: "bottom top",
          scrub: true,
          invalidateOnRefresh: true // to make it responsive
        }
      });
      console.log("running gsap to array")
    });
  }

  useEffect(() => {
    if (counter < 4) {
      getbackgroundimages()
      counter ++
    }
  }, [counter]);


  return (
    <div className="App">
      <div className="App-header">
        <div className="App-header animated animate__animated animate__fadeInRight">
          <section>
            <div className="bg"></div>
            <h1>BoomTownBlues</h1>
          </section>
          <section>
            <div className="bg"></div>
            <h1>Our Home Experts are experienced with all aspects of residential home repair.  Whether HVAC, Boiler, Roof, Windows or Renovations, we provide un-biased expert options to repair and improve your home.</h1>
          </section>
          <section>
            <div className="bg"></div>
            <h1>Most homeowners don't have the time or knowledge to deal with major repairs and renovations, let us handle the hard part!</h1>
          </section>
          <section>
            <div className="bg"></div>
            <h1>We charge a flat-rate to homeowners, so that our opinions are un-biased.</h1>
          </section>
            <section>
            <div className="bg"><h1>How it Works</h1></div>
              <div className={'subpoint'}>We have experts in almost every corner of home repair and renovation, that will act as your advisor, in your best-interests to get you the best price</div>
              <div className={'subpoint'}>No more taking their word for it, and no more googling strange plumbing and construction terms</div>
              <div className={'subpoint'}>These advisors are here, as your advocate, to explain in plain English what the job requires and how much it will cost</div>
              <div className={'subpoint'}>And we will provide all 3 contractor's full bids for you to review.  Sometimes the cheapest is the best, and sometimes its the worst</div>
              <div className={'subpoint'}>Hire your 3Bids expert today and be sure you're getting your money's worth</div>
          </section>
          <section>
            <div className="bg"></div>
            <h1>Ready for a consultation? Today, Tomorrow or the Next Day</h1>
          </section>
        </div>
      </div>
    </div>
  );
}

export default App;
